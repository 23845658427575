import React from 'react'
import style from './index.module.sass'

const TitleComponent = () => (
    <div className={style.box}>
        <div><span>Email</span></div>
        <div><span>Date</span></div>
    </div>
)

export default TitleComponent