export const formatTimestamp = (ts, full = true) => {
    const created = new Date(ts * 1000)
    const year = created.getFullYear()
    const month = created.getMonth() + 1
    const day = created.getDate()
    const hour = created.getHours()
    const minutes = created.getMinutes() < 10 ? '0' + created.getMinutes() : created.getMinutes()
    const createdFormattedFull = `${day}-${month}-${year}(${hour}:${minutes})`
    const createdFormattedSimple = `${day}-${month}-${year}`
    return full ? createdFormattedFull : createdFormattedSimple
}