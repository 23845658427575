import React from 'react'
import style from './index.module.sass'
import moment from "moment";
import {formatTimestamp} from "../../../../services/formatTimestamp";

const ItemComponent = ({email}) => {
    const created = formatTimestamp(email.created)
    return (
        <div className={style.box}>
            <div>
                <span>{email.email}</span>
            </div>
            <div>
                <span>{created}</span>
            </div>
        </div>
    )
}

export default ItemComponent