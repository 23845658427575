import React from 'react'
import style from '../../index.module.sass'
import moment from "moment";
import {MessagePathRQ} from "../../../message";
import {Link} from "react-router-dom";
import {formatTimestamp} from "../../../../services/formatTimestamp";


const ItemComponent = ({message}) => {
    const created = formatTimestamp(message.created)
    return (
        <Link to={`${MessagePathRQ}/${message.id}/`}>
            <div className={`${style.table} ${style.tableItem}`}>
                <div>
                    <span>{message.subject}</span>
                </div>

                <div>
                    <span>{created}</span>
                </div>
            </div>
        </Link>
    )
}

export default ItemComponent