import React from 'react'
import style from '../../index.module.sass'

const TitleComponent = () => (
    <div className={style.table}>
        <div>
            <span>subject</span>
        </div>
        <div>
            <span>Date</span>
        </div>
    </div>
)

export default TitleComponent